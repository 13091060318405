import api from "@/api";

const project = {
  list(params) {
    return api.get(`/project`, {
      params: params,
    });
  },
  getSports(pk) {
    return api.get(`/project/sports/${pk}`);
  },
  getGjSports(pk) {
    return api.get(`/project/sports-gyj/${pk}`);
  },
  getBd(pk) {
    return api.get(`/project/bd/${pk}`);
  },
  getNumber(pk) {
    return api.get(`/project/number/${pk}`);
  },
  printBdSucceed(pk) {
    return api.patch(`/project/bd/${pk}/succeed`);
  },
  printSportsSucceed(pk) {
    return api.patch(`/project/sports/${pk}/succeed`);
  },
  printSportsFailed(pk) {
    return api.patch(`/project/sports/${pk}/failed`);
  },
  printBdFailed(pk) {
    return api.patch(`/project/bd/${pk}/failed`);
  },
  printNumberSucceed(pk) {
    return api.patch(`/project/number/${pk}/succeed`);
  },
  printNumberFailed(pk) {
    return api.patch(`/project/number/${pk}/failed`);
  },
  exchange(pk, data) {
    return api.post(`/project/${pk}/exchange`, data);
  },
  upload(pk, data) {
    return api.post(`/project/${pk}/ticket`, data);
  },
  setStoreSmallMoney(data) {
    return api.patch(`/store/small-money`, data);
  },
  exchangeSmallMoney(data) {
    return api.patch(`/project/exchange/small`, data);
  },
  editSportsRate(pk, data) {
    return api.patch(`/project/sports/${pk}/rate`, data);
  },
  editSportsPoint(pk, data) {
    return api.patch(`/project/sports/${pk}/point`, data);
  },
  getStaffPrintPull() {
    return api.get(`/project/staff-print-pull`);
  },
  setProjectDelay(pk) {
    return api.get(`/project/${pk}/staff-print-delay`);
  },
};

export default project;
